<template>
  <div class="wrap">
    <!-- 搜索框 -->
    <div class="search-wrap">
      <van-search
        v-model="queryParams.flowName"
        show-action
        placeholder="请输入关注列表标题"
        @search="getDataList(false)">
        <template #action>
          <div @click="getDataList(false)">搜索</div>
        </template>
      </van-search>
    </div>
    <div style="height: 100vh;overflow: hidden;">
      <vo-pages :config="voConfig" :data="dataList" @pullingUp="pullingUp" @pullingDown="pullingDown" :loadedAll="loadedAll">
        <div class="receive-wrap">
          <div class="receive_main">
            <div class="receive_item" v-for="(item,index) in dataList" :key="index" @click="handleDetail(item)">
              <h2 class="receive_main_title">{{item.flowName}}</h2>
              <div class="receive_name_time">
                <div class="receive_time">
                  <img src="@/assets/todo_icon_3.png" alt="">
                  {{item.receiveTime}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </vo-pages>
    </div>
  </div>
</template>
<script>
import {followList} from '@/api/index'
export default {
  data() {
    return {
      voConfig:{
        loadedAllMsg:'没有更多关注数据了',
        pullUpMsg:'',
        loading:'加载中',
        refresh:'刷新中'
      },
      loading:false,
      dataList:[],
      queryParams:{
        pageSize:10,
        pageNum:1,
        flowName:''
      },
      total:0,
      loadedAll:false,
      isLoading:false
    }
  },
  created() {
    this.getDataList(false)
  },
  methods: {
    pullingDown () {
      this.beforePullDown = false
      this.queryParams.pageNum = 1
      this.getDataList(false)
    },
    pullingUp () {
      this.queryParams.pageNum += 1
      this.getDataList(true)
    },
    handleDetail(data){
      this.$router.push('/main?instanceId='+data.processInstId+'&modelKey='+data.modelKey+'&tenantId='+data.tenantId+'&flowTitle='+data.flowName+'&type=concern')
    },
    getDataList(loadMore = true) {
      this.loading = true;
      followList(this.queryParams).then(res => {
        if(res.code == 200) {
          const newList = res.rows;
          this.total = res.total
          if (loadMore) {
            this.dataList = this.dataList.concat(newList)
          } else {
            this.dataList = newList
          }
          if (!this.beforePullDown) {
            this.beforePullDown = true
          }
          this.loadedAll = this.total <= this.dataList.length
        }
        this.loading = false;
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.van-search{
    padding: 0;
}
.van-search__content{
    background: #F5F5F5;
    border-radius: 0.04rem;
}
.van-search__action{
    width: 0.67rem;
    height: 0.34rem;
    background: #3A75C5;
    border-radius: 0.04rem;
    color: #fff;
    text-align: center;
    margin-left: 0.06rem;
    padding: 0;
    // font-size: 0.13rem;
}
.wrap {
    background: #F5F5F5;
  .search-wrap {
    padding: 0.09rem 0.12rem;
    background: #fff;
  }
  .receive-wrap{
    background: #fff;
    margin-top: 0.09rem;
    .receive_tip{
      border-bottom: 0.01rem solid #EEEEEE;
      .receive_tip_box{
        padding: 0.07rem 0.12rem;
        display: flex;
        justify-content: space-between;
      }
      img{
        width: 0.19rem;
        height: 0.19rem;
        position: relative;
        top: 0.03rem;
      }
      .receive_title{
        display: inline-block;
        color: #3A75C5;
        font-size: 0.18rem;
        margin-left: 0.06rem;
      }
      .receive_tip_right{
        height: 0.25rem;
        line-height: 0.25rem;
        color: #888888;
      }
    }
    .receive_main{
      padding: 0 0.12rem;
      .receive_item{
        padding-top: 0.14rem;
        padding-bottom: 0.18rem;
        border-bottom: 0.01rem solid #EEEEEE;
        .receive_main_title{
          padding-bottom: 0.07rem;
          font-size: 0.15rem;
          color: #333333;
          word-break:break-all;
        }
        .receive_name_time{
          // display: flex;
          // justify-content: space-between;
          .receive_time{
            display: inline-block;
            color: #888888;
            img{
              width: 0.13rem;
              height: 0.13rem;
              position: relative;
              top: 0.02rem;
            }
          }
        }
      }
      .receive_item:last-child{
        border: 0;
      }
    }

  }
}
</style>
